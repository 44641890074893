<template>
    <div>
        <signCard v-if="showMe" />
        
    </div>
</template>

<script>
import signCard from '@/components/signCard.vue'
export default{
    components:{signCard},
    data() {
        return {
            cardid: this.$route.params.id,
            showMe: true
        }
    },
    methods:{
        showit() {
            console.log(this.cardid);
        }
    },
    created(){
        document.getElementById('showSign').click();
    }
}
</script>